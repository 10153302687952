import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/vkuramshin/p/www/tkcrm/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "возможности"
    }}>{`Возможности`}</h1>
    <table>
	<thead>
		<tr>
			<th>Сокращение</th>
			<th>Расшифровка</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>т/м</td>
			<td>Транспортное средство</td>
		</tr>
		<tr>
			<td>о/м</td>
			<td>Объект мониторинга</td>
		</tr>
		<tr>
			<td>п/в</td>
			<td>Период времени</td>
		</tr>
		<tr>
			<td>м/п</td>
			<td>Мобильное приложение</td>
		</tr>
	</tbody>
    </table>
    <h2 {...{
      "id": "возможности-для-логиста"
    }}>{`Возможности для логиста`}</h2>
    <ul>
      <li parentName="ul">{`Отображение любого количества о/м на карте`}</li>
      <li parentName="ul">{`Группировка объектов`}</li>
      <li parentName="ul">{`Отображение текущего состояние т/с (Заведено, в движении, не заведено)`}</li>
      <li parentName="ul">{`Отображение всех стоянок и их длительность`}</li>
      <li parentName="ul">{`Отображение скоростных нарушений`}</li>
      <li parentName="ul">{`Отображение агрессивного и некачественного вождения`}</li>
      <li parentName="ul">{`Отображение подробного трека за любой п/в и текущего состояния о/м на нем`}</li>
      <li parentName="ul">{`Отображение текущих географических параметров о/м`}</li>
      <li parentName="ul">{`Шкала активности о/м за любой п/в (В движении, на холостых, стоянка)`}</li>
      <li parentName="ul">{`Контроль сливов и заправок и расхода топлива на т/с по ДУТ и CAN`}</li>
      <li parentName="ul">{`Отображение состояния датчиков о/м за любой п/в`}</li>
      <li parentName="ul">{`Немедленные уведомления в Telegram и SMS по состоянию датчиков`}</li>
      <li parentName="ul">{`Построение графиков состояния датчиков`}</li>
      <li parentName="ul">{`Отображения уведомлений по о/м за любой п/в`}</li>
      <li parentName="ul">{`Подробная статистика об активности о/м за любой п/в`}</li>
      <li parentName="ul">{`Формирование неограниченного количества отчетов по: рейсам, топливу, активности, качеству вождения, нарушениям, датчиков по любому о/м. Как по группе, так и по отдельному о/м`}</li>
      <li parentName="ul">{`Уведомления о том, что водитель отключил питание на IoT устройстве`}</li>
      <li parentName="ul">{`Уведомление и отображение на карте о том, что батарея разряжена на о/м`}</li>
      <li parentName="ul">{`Уведомление о том, что курьер отключил отслеживание в м/п`}</li>
      <li parentName="ul">{`Уведомление о том, что курьер отключил уведомления в м/п`}</li>
      <li parentName="ul">{`Указание подробных параметров т/с`}</li>
      <li parentName="ul">{`Создание подробных маршрутов и задач для водителей с возможностью гибкой настройки`}</li>
      <li parentName="ul">{`Онлайн чат для общения и отправки документов сотрудникам (скоро)`}</li>
      <li parentName="ul">{`Геозоны (скоро)`}</li>
    </ul>
    <h2 {...{
      "id": "возможности-для-водителя"
    }}>{`Возможности для водителя`}</h2>
    <ul>
      <li parentName="ul">{`Краткая и подробная статистика об активности и качестве вождения`}</li>
      <li parentName="ul">{`Онлайн чат для общения и отправки документов диспетчерам`}</li>
      <li parentName="ul">{`Возможность записывать трек внутри приложения`}</li>
      <li parentName="ul">{`Памятка о личных данных, т/с и организации`}</li>
      <li parentName="ul">{`Поиск ближайших стоянок, СТО, банкоматов и прочее`}</li>
    </ul>
    <h2 {...{
      "id": "особенности"
    }}>{`Особенности`}</h2>
    <ul>
      <li parentName="ul">{`Нет ограничений на количество добавленного о/м`}</li>
      <li parentName="ul">{`Нет ограничений на количество добавленных сотрудников (водители, курьеры)`}</li>
      <li parentName="ul">{`Возможность использовать систему без участия интегратора. Самостоятельная регистрация, самостоятельное обслуживание.`}</li>
      <li parentName="ul">{`Добавление и редактирование сотрудников компании`}</li>
      <li parentName="ul">{`Добавление и редактирование транспортных средств`}</li>
      <li parentName="ul">{`Выставление счетов на любые реквизиты, закрепление за каждым транспортным средством отдельную организацию. Автоматическое выставление счетов.`}</li>
      <li parentName="ul">{`Возможность оплачивать как с карты, так и с расчетного счета. моментальное зачисление если по карте`}</li>
      <li parentName="ul">{`Для т/с возможность принимать спутниковые данные как с IoT так и с м/п`}</li>
      <li parentName="ul">{`Возможность отключать прием данных с устройств`}</li>
      <li parentName="ul">{`Данные с IoT устройств отображаются моментально. В среднем актуальность в районе 5 секунд. Зависит от настроек терминала. Можно и быстрее (это всё, что касается треков - датчики моментально срабатывают)`}</li>
      <li parentName="ul">{`Автоматическое настройка IoT устройства в считанные секунды`}</li>
      <li parentName="ul">{`Автоматическая настройка системных датчиков IoT устройства`}</li>
      <li parentName="ul">{`Автоматическая настройка и калибровка датчика зажигания по напряжению сети`}</li>
      <li parentName="ul">{`Встроенный режим диагностики трекеров (высокое напряжение, ошибка симкарты) всё это уведомляется`}</li>
      <li parentName="ul">{`Использование частотных, дискретных, аналоговых (битовый, видео, аудио скоро)`}</li>
      <li parentName="ul">{`Если с о/м не поступают данные, то оплаченные дни не списываются с т/с`}</li>
      <li parentName="ul">{`Возможность разбивки байтовых данных с CAN на битовые и установка как дискретных так и аналоговых датчиков на каждый бит (в тесте)`}</li>
      <li parentName="ul">{`Все данные обновляются в режиме реального времени`}</li>
      <li parentName="ul">{`Возможность устанавливать любые уведомления в Telegram (датчики, изменение статуса задачи)`}</li>
      <li parentName="ul">{`Возможность устанавливать голосовые оповещения на любые датчики (скоро)`}</li>
      <li parentName="ul">{`Встроенное решение EcoDrive`}</li>
      <li parentName="ul">{`Встроенное решение ELD`}</li>
      <li parentName="ul">{`Интуитивно понятный, современный интерфейс`}</li>
      <li parentName="ul">{`Возможность устанавливать программное обеспечение на любой платформе. у кого планшет и мобильный телефон - не ограничены по функционалу`}</li>
      <li parentName="ul">{`Статистика по объекту доступна без формирования отчетов - прямо в мониторинге`}</li>
      <li parentName="ul">{`Онлайн чат для общения с сотрудником, курьером, водителем. пересылка документов (скоро)`}</li>
      <li parentName="ul">{`Водителю в приложении доступна статистика по его работе`}</li>
      <li parentName="ul">{`Автоматическое, моментальное построение треков в считанные`}</li>
      <li parentName="ul">{`Отсутствие не нужных манипуляций по выбору пикселей, цвету, иконок и прочей ерунды`}</li>
      <li parentName="ul">{`Высокая скорость работы платформы`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      